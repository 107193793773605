<template>
<div class="container-fluid pb-5">
    
    <div class="row ">
        <div class="col-12 pt-4">
            <div class="" id="myTabContent">
                <div class="tab-pane fade show active px-2 pt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row">
                        <div class="container-fluid  pb-5">
                            <div class="row">

                                <div class="col-12 pt-4">
                                    <report title="Earnings Over Time" :query="earnings_query" metric="total_amount" y_prefix="$ "></report>

                                </div>
                                <div class="col-lg-6 pt-4">
                                    <report title="Sales Over Time" :query="sales_query" metric="transaction_count" y_prefix=""></report>

                                </div>
                                <div class="col-lg-6 pt-4">
                                    <report title="Customers Over Time" :query="customers_query" metric="customer_count" y_prefix=""></report>

                                </div>
                                <div class="col-lg-6 pt-4">
                                    <report title="Items Sold Over Time" :query="items_query" metric="item_count" y_prefix=""></report>

                                </div>
                                <div class="col-lg-6 pt-4">
                                    <report title="Average Amount" :query="avg_amount_per_period" metric="avg_amount_per_period" y_prefix="$"></report>
                                </div>
                                <div class="col-lg-6 pt-4">
                                    <report title="Average Amount Per Item" :query="amount_per_item" metric="amount_per_item" y_prefix="$"></report>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    Chart
} from 'frappe-charts/dist/frappe-charts.esm.js'
var jsonata = require("jsonata");
import Report from "../components/Report.vue";
export default {
    name: "overview",

    data() {
        return {
            magic_flag: false,

            title_t: true,
            snippet_title: "Title",
            content: "",
            tag: "",
            tags: [],
            transaction_summary: {},
            over: "DAY",
            earnings_query: {
                "from": "sales",
                "show": [
                    "sum(total_amount) as total_amount",

                ],
                over: "DAY",
            },
            sales_query: {
                "from": "sales",
                "show": [

                    "sum(transaction_count) as transaction_count"
                ],
                "over": "DAY"
            },
            customers_query: {
                "from": "sales",
                "show": [
                    "sum(customer_count) as customer_count"
                ],
                "over": "DAY"
            },
            items_query: {
                "from": "sales",
                "show": [
                    "sum(item_count) as item_count"
                ],
                "over": "DAY"
            },
            amount_per_item: {
                "from": "sales",
                "show": [
                    "sum(total_amount)/sum(item_count) as amount_per_item"
                ],

                "over": "MONTH",
                "since": "2022-01-21"
            },
            avg_amount_per_period: {
                "from": "sales",
                "show": [
                    "avg(total_amount) as avg_amount_per_period"
                ],

                "over": "MONTH",
                "since": "2022-01-21"
            },
        };
    },
    components: {
        Report
    },
    options: {
        styleIsolation: "shared",
    },

    methods: {
        set_over(over) {
            this.over = over;
            this.earnings_query.over = over;
            this.sales_query.over = over;
            this.customers_query.over = over;
            this.items_query.over = over;
        },
    },
};
</script>

<style>
</style>
